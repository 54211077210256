@import '../../styles/_partials';

#nav {
  position: fixed;
  width: 100%;
  z-index: 1;
}

#main-nav {
  height: 32px;
  display: flex;
  border-bottom: 1px solid;

  li {
    @extend %vh-center;
    
    flex: 1;

    &:nth-of-type(even) {
      border-style: solid;
      border-width: 0px 1px;
    } 

    a {
      width: 100%;
    }
  }
}